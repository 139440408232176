// TODO download modules ///////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
// TODO custom modules /////////////////////////////////////////////////////////////////////////////////////////////////
import * as components from "../../js/components";
import * as constants from "../../js/constants";

import * as utils from "../../js/utils";
// TODO default export const page //////////////////////////////////////////////////////////////////////////////////////
export const RationalReglamPage = () => {
  // TODO react hooks variables ////////////////////////////////////////////////////////////////////////////////////////

  // TODO return page //////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="m-0 p-0">
      <components.HeaderComponent />
      <main>
        <div className="btn-group text-start w-100 m-0 p-0">
          <Link to={"/rational_template"} className="btn btn-sm btn-primary m-1 p-2">
            к примеру (шаблону) рац. предложения {" =>"} 
          </Link>
          <Link to={"/rational_create"} className="btn btn-sm btn-success m-1 p-2">
            {"<="} к подаче рац. предложения
          </Link>
        </div>

        <div className="d-flex text-center justify-content-center text-alight-center">
        <img
            src={utils.GetStaticFile("/img/rational.jpg")}
            className="card-img-top img-fluid w-75 m-0 p-1"
            alt="изображение отсутствует"
          />
        </div>

        <div className="btn-group text-start w-100 m-0 p-0">
          <Link to={"/rational_template"} className="btn btn-sm btn-primary m-1 p-2">
            к примеру (шаблону) рац. предложения {" =>"} 
          </Link>
          <Link to={"/rational_create"} className="btn btn-sm btn-success m-1 p-2">
            {"<="} к подаче рац. предложения
          </Link>
        </div>
      </main>
      <components.FooterComponent />
    </div>
  );
};
