// TODO download modules ///////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// TODO custom modules /////////////////////////////////////////////////////////////////////////////////////////////////
import * as components from "../../js/components";
import * as constants from "../../js/constants";
import * as utils from "../../js/utils";
// TODO default export const page //////////////////////////////////////////////////////////////////////////////////////
export const Test2 = () => {

  const dispatch = useDispatch();
  const test2Store = useSelector((state) => state.test2Store);
  const {
    load: loadtest2Store,
    data: datatest2Store,
  } = test2Store;

  const handlerSubmit = async (e) => {
    try {
      e.preventDefault();
    } catch (error) {}
    const form = {
      "Action-type": "Test2",
      param: `test test test`,
    };
    dispatch(
      utils.ActionConstructorUtility(
        form,
        "/api/test2/",
        "POST",
        30000,
        constants.TEST2
      )
    );
  }

  useEffect(() => {
    if (datatest2Store) {
      console.log(datatest2Store)
    }
  }, [datatest2Store]);


  // TODO return page //////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="m-0 p-0">
      <components.HeaderComponent />
      <main>
        <div className="card shadow custom-background-transparent-low">
        Test2
        {loadtest2Store && <small>loading ...</small>}

        <h6>Array length: {datatest2Store && datatest2Store["users"].length}</h6>
        
        <button type="submit" onClick={handlerSubmit} className="btn btn-sm btn-primary">
          <i className="fa-solid fa-circle-check m-0 p-1" />
          get
        </button>
        </div>
      </main>
      <components.FooterComponent />
    </div>
  );
};
