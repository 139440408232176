// TODO download modules ///////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
// TODO custom modules /////////////////////////////////////////////////////////////////////////////////////////////////
import * as components from "../../js/components";
import * as constants from "../../js/constants";

import * as utils from "../../js/utils";
// TODO default export const page //////////////////////////////////////////////////////////////////////////////////////
export const RationalModerateChangePage = () => {
  // TODO react hooks variables ////////////////////////////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const id = useParams().id;
  // TODO custom variables /////////////////////////////////////////////////////////////////////////////////////////////
  const [firstRefresh, firstRefreshSet] = useState(true);
  //////////////////////////////////////////////////////////
  const [moderate, moderateSet] = useState("");
  const [moderateComment, moderateCommentSet] = useState("");

  const [subdivision, subdivisionSet] = useState("");
  const [category, categorySet] = useState("");
  const [avatar, avatarSet] = useState(null);
  const [clearImage, clearImageSet] = useState(false);
  const [name, nameSet] = useState("");
  const [place, placeSet] = useState("");
  const [problem, problemSet] = useState("");
  const [solution, solutionSet] = useState("");
  const [effect, effectSet] = useState("");
  const [report, reportSet] = useState("");
  const [additional1, additional1Set] = useState(null);
  const [additional2, additional2Set] = useState(null);
  const [additional3, additional3Set] = useState(null);
  const [additional4, additional4Set] = useState(null);
  const [additional5, additional5Set] = useState(null);
  const [user1, user1Set] = useState("Вы");
  const [user1Perc, user1PercSet] = useState("100");
  const [user2, user2Set] = useState("");
  const [user2Perc, user2PercSet] = useState("");
  const [user3, user3Set] = useState("");
  const [user3Perc, user3PercSet] = useState("");
  const [user4, user4Set] = useState("");
  const [user4Perc, user4PercSet] = useState("");
  const [user5, user5Set] = useState("");
  const [user5Perc, user5PercSet] = useState("");
  // TODO react store variables ////////////////////////////////////////////////////////////////////////////////////////
  const rationalDetailStore = useSelector((state) => state.rationalDetailStore);
  const {
    data: dataRationalDetail,
  } = rationalDetailStore;
  //////////////////////////////////////////////////////////
  const rationalModerateStore = useSelector((state) => state.rationalModerateStore);
  const {
    data: dataRationalModerate,
  } = rationalModerateStore;
  //////////////////////////////////////////////////////////
  const rationalChangeStore = useSelector((state) => state.rationalChangeStore);
  const {
    data: dataRationalChange,
  } = rationalChangeStore;
  //////////////////////////////////////////////////////////
  const rationalCommentDeleteStore = useSelector(
    (state) => state.rationalCommentDeleteStore
  );
  const {
    data: dataRationalCommentDelete,
  } = rationalCommentDeleteStore;
  // TODO reset state //////////////////////////////////////////////////////////////////////////////////////////////////
  const resetState = async (e) => {
    try {
      e.preventDefault();
    } catch (error) {}
    clearImageSet(false);
    dispatch({ type: constants.RATIONAL_DETAIL.reset });
    dispatch({
      type: constants.RATIONAL_MODERATE.reset,
    });
    dispatch({
      type: constants.RATIONAL_CHANGE.reset,
    });
    dispatch({
      type: constants.RATIONAL_COMMENT_DELETE.reset,
    });
  };
  // TODO useEffect hooks //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!dataRationalDetail) {
      const form = {
        "Action-type": "RATIONAL_DETAIL",
        id: id,
      };
      dispatch(
        utils.ActionConstructorUtility(
          form,
          "/api/auth/rational/",
          "POST",
          30000,
          constants.RATIONAL_DETAIL
        )
      );
    } else {
      if (firstRefresh) {
        firstRefreshSet(false);
        resetState();
      } else {
        subdivisionSet(dataRationalDetail["subdivision_char_field"])
        categorySet(dataRationalDetail["category_char_field"])
        nameSet(dataRationalDetail["name_char_field"])
        placeSet(dataRationalDetail["place_char_field"])
        problemSet(dataRationalDetail["problem_text_field"])
        solutionSet(dataRationalDetail["solution_text_field"])
        effectSet(dataRationalDetail["effect_text_field"])
        if (dataRationalDetail["author_1_perc_char_field"]){
          user1PercSet(parseInt(dataRationalDetail["author_1_perc_char_field"].slice(0, -1)))
        }
        if (dataRationalDetail["author_2_perc_char_field"]){
          user2PercSet(parseInt(dataRationalDetail["author_2_perc_char_field"].slice(0, -1)))
        }
        if (dataRationalDetail["author_3_perc_char_field"]){
          user3PercSet(parseInt(dataRationalDetail["author_3_perc_char_field"].slice(0, -1)))
        }
        if (dataRationalDetail["author_4_perc_char_field"]){
          user4PercSet(parseInt(dataRationalDetail["author_4_perc_char_field"].slice(0, -1)))
        }
        if (dataRationalDetail["author_5_perc_char_field"]){
          user5PercSet(parseInt(dataRationalDetail["author_5_perc_char_field"].slice(0, -1)))
        }
      }
    }
  }, [dataRationalDetail, firstRefreshSet]);
  //////////////////////////////////////////////////////////
  useEffect(() => {
    if (dataRationalModerate) {
      utils.Sleep(10).then(() => {
        resetState();
      });
    }
  }, [dataRationalModerate]);
  //////////////////////////////////////////////////////////
  useEffect(() => {
    if (dataRationalChange) {
      utils.Sleep(10).then(() => {
        resetState();
      });
    }
  }, [dataRationalChange]);
  //////////////////////////////////////////////////////////
  useEffect(() => {
    if (dataRationalCommentDelete) {
      utils.Sleep(10).then(() => {
        resetState();
      });
    }
  }, [dataRationalCommentDelete]);
  // TODO handlers /////////////////////////////////////////////////////////////////////////////////////////////////////
  const handlerModerateSubmit = (e) => {
    e.preventDefault();
    const form = {
      "Action-type": "RATIONAL_UPDATE",
      id: id,
      moderate: moderate,
      moderateComment: moderateComment,
    };
    dispatch(
      utils.ActionConstructorUtility(
        form,
        "/api/auth/rational/",
        "POST",
        30000,
        constants.RATIONAL_MODERATE
      )
    );
  };
  //////////////////////////////////////////////////////////
  const handlerChangeSubmit = async (e) => {
    try {
      e.preventDefault();
    } catch (error) {}
    const form = {
      "Action-type": "RATIONAL_UPDATE",
      id: id,
      subdivision: subdivision,
      category: category,
      avatar: avatar,
      clearImage: clearImage,
      name: name,
      place: place,
      problem: problem,
      solution: solution,
      effect: effect,
      report: report,
      additional1: additional1,
      additional2: additional2,
      additional3: additional3,
      additional4: additional4,
      additional5: additional5,
      user1Perc: `${user1Perc}%`,
      user2Perc: `${user2Perc}%`,
      user3Perc: `${user3Perc}%`,
      user4Perc: `${user4Perc}%`,
      user5Perc: `${user5Perc}%`,
    };
    dispatch(
      utils.ActionConstructorUtility(
        form,
        "/api/auth/rational/",
        "POST",
        30000,
        constants.RATIONAL_CHANGE
      )
    );
  };
  //////////////////////////////////////////////////////////
  const handlerChangeReset = async (e) => {
    try {
      e.preventDefault();
    } catch (error) {}
    resetState();
  };
  //////////////////////////////////////////////////////////
  const handlerCommentDelete = async ({ commentId }) => {
    let isConfirm = window.confirm("Удалить выбранный комментарий?");
    if (isConfirm) {
      const form = {
        "Action-type": "RATIONAL_COMMENT_DELETE",
        id: commentId,
      };
      dispatch(
        utils.ActionConstructorUtility(
          form,
          "/api/auth/rational/",
          "POST",
          30000,
          constants.RATIONAL_COMMENT_DELETE
        )
      );
    }
  };
  // TODO return page //////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="m-0 p-0">
      <components.HeaderComponent />
      <main className="container">
        <div className="btn-group m-0 p-1 text-start w-100">
          <Link
            to={"/rational_moderate_list"}
            className="btn btn-sm btn-primary m-1 p-2"
          >
            {"<="} назад к списку
          </Link>
        </div>
        <components.AccordionComponent
          key_target={"accordion1"}
          isCollapse={false}
          title={"Модерация:"}
          text_style="text-danger"
          header_style="bg-danger bg-opacity-10 custom-background-transparent-low"
          body_style="bg-light bg-opacity-10 custom-background-transparent-low"
        >
          {
            <ul className="row-cols-auto row-cols-sm-auto row-cols-md-auto row-cols-lg-auto justify-content-center text-center m-0 p-0">
              <form className="m-0 p-0" onSubmit={handlerModerateSubmit}>
                <div className="card shadow custom-background-transparent-hard m-0 p-0">
                  <div className="card-header m-0 p-0">
                    <label className="lead m-0 p-1">
                      Выберите заключение по идеи{" "}
                      <p className="fw-bold text-secondary m-0 p-0">
                        заполните комментарий "на доработку", чтобы автор идеи
                        его увидел
                      </p>
                    </label>
                  </div>
                  <div className="card-body m-0 p-0">
                    <label className="form-control-sm text-center m-0 p-1">
                      Заключение:
                      <select
                        required
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={moderate}
                        onChange={(e) => moderateSet(e.target.value)}
                      >
                        <option value="">не выбрано</option>
                        <option value="на модерации">на модерации</option>
                        <option value="на доработку">на доработку</option>
                        <option value="скрыто">скрыто</option>
                        <option value="принято">принято</option>
                      </select>
                      <small className="text-danger">* обязательно</small>
                    </label>
                    {moderate === "на доработку" && (
                      <label className="w-75 form-control-sm">
                        Комментарий:
                        <input
                          type="text"
                          className="form-control form-control-sm text-center m-0 p-1"
                          value={moderateComment}
                          placeholder="вводите комментарий тут..."
                          minLength="1"
                          maxLength="300"
                          onChange={(e) =>
                            moderateCommentSet(
                              e.target.value.replace(
                                utils.GetRegexType({
                                  numbers: true,
                                  cyrillic: true,
                                  space: true,
                                  punctuationMarks: true,
                                }),
                                ""
                              )
                            )
                          }
                        />
                        <small className="text-danger m-0 p-0">
                          * обязательно
                          <small className="custom-color-warning-1 m-0 p-0">
                            {" "}
                            * только кириллица, цифры и пробел
                          </small>
                          <small className="text-muted m-0 p-0">
                            {" "}
                            * длина: не более 300 символов
                          </small>
                        </small>
                      </label>
                    )}
                  </div>
                  <components.StoreStatusComponent
                    storeStatus={rationalModerateStore}
                    keyStatus={"rationalModerateStore"}
                    consoleLog={constants.DEBUG_CONSTANT}
                    showLoad={true}
                    loadText={""}
                    showData={true}
                    dataText={""}
                    showError={true}
                    errorText={""}
                    showFail={true}
                    failText={""}
                  />
                  <div className="card-footer m-0 p-0">
                    <ul className="btn-group row nav row-cols-auto row-cols-md-auto row-cols-lg-auto justify-content-center m-0 p-0">
                      <button
                        className="btn btn-sm btn-danger m-1 p-2"
                        type="submit"
                      >
                        <i className="fa-solid fa-circle-check m-0 p-1" />
                        вынести заключение
                      </button>
                    </ul>
                  </div>
                </div>
              </form>
            </ul>
          }
        </components.AccordionComponent>
        <components.StoreStatusComponent
          storeStatus={rationalDetailStore}
          keyStatus={"rationalDetailStore"}
          consoleLog={constants.DEBUG_CONSTANT}
          showLoad={true}
          loadText={""}
          showData={false}
          dataText={""}
          showError={true}
          errorText={""}
          showFail={true}
          failText={""}
        />
        {dataRationalDetail && (
          <ul className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 justify-content-center text-center m-0 p-1">
            <form className="m-0 p-0" onSubmit={handlerChangeSubmit}>
              <div className="card shadow custom-background-transparent-low m-0 p-0">
                <div className="card-header bg-success bg-opacity-10 m-0 p-3">
                  <h6 className="lead fw-bold m-0 p-0">
                    {dataRationalDetail["name_char_field"]}
                  </h6>
                  <h6 className="text-danger lead small m-0 p-0">
                    {" [ "}
                    {utils.GetSliceString(
                      dataRationalDetail["status_moderate_char_field"],
                      30
                    )}
                    {" : "}
                    {utils.GetSliceString(
                      dataRationalDetail["comment_postmoderate_char_field"],
                      30
                    )}
                    {" ]"}
                  </h6>
                </div>
                <div className="card-body m-0 p-0">
                  <div className="d-flex justify-content-between m-0 p-0">
                    <label className="form-control-sm text-center m-0 p-1">
                      Подразделение:
                      <select
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={subdivision}
                        required
                        onChange={(e) => subdivisionSet(e.target.value)}
                      >
                        <option className="m-0 p-0" value="">
                          не указано
                        </option>
                        <option
                          className="m-0 p-0"
                          value="автотранспортное предприятие"
                        >
                          автотранспортное предприятие
                        </option>
                        <option
                          className="m-0 p-0"
                          value="горно-транспортный комплекс"
                        >
                          горно-транспортный комплекс
                        </option>
                        <option
                          className="m-0 p-0"
                          value="обогатительный комплекс"
                        >
                          обогатительный комплекс
                        </option>
                        <option
                          className="m-0 p-0"
                          value="управление предприятия"
                        >
                          управление предприятия
                        </option>
                        <option className="m-0 p-0" value="энергоуправление">
                          энергоуправление
                        </option>
                      </select>
                      <small className="text-danger m-0 p-0">
                        * обязательно
                      </small>
                    </label>
                    <label className="form-control-sm text-center m-0 p-1">
                      Зарегистрировано № {dataRationalDetail["number_char_field"]}
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="form-control-sm text-center m-0 p-1">
                      Категория:
                      <select
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={category}
                        required
                        onChange={(e) => categorySet(e.target.value)}
                      >
                        <option className="m-0 p-0" value="">
                          не указано
                        </option>
                        <option className="m-0 p-0" value="индустрия 4.0">
                          индустрия 4.0
                        </option>
                        <option className="m-0 p-0" value="инвестиции">
                          инвестиции
                        </option>
                        <option className="m-0 p-0" value="инновации">
                          инновации
                        </option>
                        <option className="m-0 p-0" value="модернизация">
                          модернизация
                        </option>
                        <option className="m-0 p-0" value="экология">
                          экология
                        </option>
                        <option className="m-0 p-0" value="спорт/культура">
                          спорт/культура
                        </option>
                        <option className="m-0 p-0" value="другое">
                          другое
                        </option>
                      </select>
                      <small className="text-danger m-0 p-0">
                        * обязательно
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-1">
                    <img
                      src={
                        dataRationalDetail["image_field"]
                          ? utils.GetStaticFile(dataRationalDetail["image_field"])
                          : utils.GetStaticFile(
                              "/media/default/rational/default_rational.jpg"
                            )
                      }
                      className="card-img-top img-fluid w-25 m-0 p-1"
                      alt="изображение отсутствует"
                    />
                    <label className="form-control-sm form-switch text-center m-0 p-1">
                      Удалить текущее изображение:
                      <input
                        type="checkbox"
                        className="form-check-input m-0 p-1"
                        id="flexSwitchCheckDefault"
                        defaultChecked={clearImage}
                        onClick={(e) => clearImageSet(!clearImage)}
                      />
                    </label>
                    <label className="form-control-sm text-center m-0 p-1">
                      Аватарка-заставка:
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        accept=".jpg, .png"
                        onChange={(e) => avatarSet(e.target.files[0])}
                      />
                      <small className="text-muted m-0 p-0">
                        * не обязательно
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="form-control-sm text-center w-75 m-0 p-1">
                      Название:
                      <input
                        type="text"
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={name}
                        placeholder="введите название тут..."
                        required
                        minLength="1"
                        maxLength="300"
                        onChange={(e) =>
                          nameSet(
                            e.target.value.replace(
                              utils.GetRegexType({
                                numbers: true,
                                cyrillic: true,
                                space: true,
                                punctuationMarks: true,
                              }),
                              ""
                            )
                          )
                        }
                      />
                      <small className="text-danger m-0 p-0">
                        * обязательно
                        <small className="custom-color-warning-1 m-0 p-0">
                          {" "}
                          * только кириллица, цифры, пробел и знаки препинания
                        </small>
                        <small className="text-muted m-0 p-0">
                          {" "}
                          * длина: не более 300 символов
                        </small>
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="w-50 form-control-sm m-0 p-1">
                      Место внедрения:
                      <input
                        type="text"
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={place}
                        placeholder="введите место внедрения тут..."
                        required
                        minLength="1"
                        maxLength="300"
                        onChange={(e) =>
                          placeSet(
                            e.target.value.replace(
                              utils.GetRegexType({
                                numbers: true,
                                cyrillic: true,
                                space: true,
                                punctuationMarks: true,
                              }),
                              ""
                            )
                          )
                        }
                      />
                      <small className="text-danger m-0 p-0">
                        * обязательно
                        <small className="custom-color-warning-1 m-0 p-0">
                          {" "}
                          * только кириллица, цифры, пробел и знаки препинания
                        </small>
                        <small className="text-muted m-0 p-0">
                          {" "}
                          * длина: не более 300 символов
                        </small>
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="w-100 form-control-sm m-0 p-1">
                      Проблема:
                      <textarea
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={problem}
                        required
                        placeholder="введите в это поле - 'какую проблему Вы хотите решить?'"
                        minLength="1"
                        maxLength="3000"
                        rows="3"
                        onChange={(e) =>
                          problemSet(
                            e.target.value.replace(
                              utils.GetRegexType({
                                numbers: true,
                                latin: true,
                                cyrillic: true,
                                space: true,
                                punctuationMarks: true,
                              }),
                              ""
                            )
                          )
                        }
                      />
                      <small className="text-danger m-0 p-0">
                        * обязательно
                        <small className="text-muted m-0 p-0">
                          {" "}
                          * длина: не более 3000 символов
                        </small>
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="w-100 form-control-sm m-0 p-1">
                      Решение:
                      <textarea
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={solution}
                        required
                        placeholder="введите в это поле - 'какое решение Вы предлагаете?'"
                        minLength="1"
                        maxLength="3000"
                        rows="3"
                        onChange={(e) =>
                          solutionSet(
                            e.target.value.replace(
                              utils.GetRegexType({
                                numbers: true,
                                latin: true,
                                cyrillic: true,
                                space: true,
                                punctuationMarks: true,
                              }),
                              ""
                            )
                          )
                        }
                      />
                      <small className="text-danger m-0 p-0">
                        * обязательно
                        <small className="text-muted m-0 p-0">
                          {" "}
                          * длина: не более 3000 символов
                        </small>
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="w-100 form-control-sm m-0 p-1">
                      Эффект:
                      <textarea
                        className="form-control form-control-sm text-center m-0 p-1"
                        value={effect}
                        required
                        placeholder="введите в это поле - 'какого эффекта Вы планируете достичь?'"
                        minLength="1"
                        maxLength="3000"
                        rows="3"
                        onChange={(e) =>
                          effectSet(
                            e.target.value.replace(
                              utils.GetRegexType({
                                numbers: true,
                                latin: true,
                                cyrillic: true,
                                space: true,
                                punctuationMarks: true,
                              }),
                              ""
                            )
                          )
                        }
                      />
                      <small className="text-danger m-0 p-0">
                        * обязательно
                        <small className="text-muted m-0 p-0">
                          {" "}
                          * длина: не более 3000 символов
                        </small>
                      </small>
                    </label>
                  </div>
                  <div className="m-0 p-0">
                    <label className="form-control-sm text-center m-0 p-1">
                      Отчёт о внедрении(заполняется после реализации):
                      {dataRationalDetail["report_file_field"] && 
                        <a
                          className="btn btn-sm btn-primary w-75 m-0 p-1"
                          href={utils.GetStaticFile(
                            `${dataRationalDetail["report_file_field"]}`
                          )}
                        >
                          Скачать отчёт
                        </a>
                      }
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        onChange={(e) => reportSet(e.target.files[0])}
                      />
                      <small className="text-muted">* не обязательно</small>
                    </label>
                    
                  </div>
                  <div className="m-0 p-0">
                    <p className="text-muted m-0 p-1">
                      Ниже Вы можете прикрепить 5 файлов, это могут быть: чертежи, схемы, pdf/word/excel - документы, дополнительные фото или архив с информацией.
                    </p>
                  </div>
                  <div className="m-0 p-0">
                    Вложение № 1:
                    <label className="form-control-sm text-center m-0 p-1">
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        onChange={(e) => additional1Set(e.target.files[0])}
                      />
                      <small className="text-muted">* не обязательно</small>
                    </label>
                    {dataRationalDetail["additional1_file_field"] && 
                      <a
                        className="btn btn-sm btn-primary w-75 m-0 p-1"
                        href={utils.GetStaticFile(
                          `${dataRationalDetail["additional1_file_field"]}`
                        )}
                      >
                        Скачать вложение 1
                      </a>
                    }
                  </div>
                  <div className="m-0 p-0">
                    <label className="form-control-sm text-center m-0 p-1">
                      Вложение № 2:
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        onChange={(e) => additional2Set(e.target.files[0])}
                      />
                      <small className="text-muted">* не обязательно</small>
                    </label>
                    {dataRationalDetail["additional2_file_field"] && 
                      <a
                        className="btn btn-sm btn-primary w-75 m-0 p-1"
                        href={utils.GetStaticFile(
                          `${dataRationalDetail["additional2_file_field"]}`
                        )}
                      >
                        Скачать вложение 2
                      </a>
                    }
                    <label className="form-control-sm text-center m-0 p-1">
                      Вложение № 3:
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        onChange={(e) => additional3Set(e.target.files[0])}
                      />
                      <small className="text-muted">* не обязательно</small>
                    </label>
                    {dataRationalDetail["additional3_file_field"] && 
                      <a
                        className="btn btn-sm btn-primary w-75 m-0 p-1"
                        href={utils.GetStaticFile(
                          `${dataRationalDetail["additional3_file_field"]}`
                        )}
                      >
                        Скачать вложение 3
                      </a>
                    }
                    <label className="form-control-sm text-center m-0 p-1">
                      Вложение № 4:
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        onChange={(e) => additional4Set(e.target.files[0])}
                      />
                      <small className="text-muted">* не обязательно</small>
                    </label>
                    {dataRationalDetail["additional4_file_field"] && 
                      <a
                        className="btn btn-sm btn-primary w-75 m-0 p-1"
                        href={utils.GetStaticFile(
                          `${dataRationalDetail["additional4_file_field"]}`
                        )}
                      >
                        Скачать вложение 4
                      </a>
                    }
                    <label className="form-control-sm text-center m-0 p-1">
                      Вложение № 5:
                      <input
                        type="file"
                        className="form-control form-control-sm text-center m-0 p-1"
                        onChange={(e) => additional5Set(e.target.files[0])}
                      />
                      <small className="text-muted">* не обязательно</small>
                    </label>
                    {dataRationalDetail["additional5_file_field"] && 
                      <a
                        className="btn btn-sm btn-primary w-75 m-0 p-1"
                        href={utils.GetStaticFile(
                          `${dataRationalDetail["additional5_file_field"]}`
                        )}
                      >
                        Скачать вложение 5
                      </a>
                    }
                  </div>

                  <div className="m-0 p-0">
                    {dataRationalDetail["author_1_foreign_key_field"] && (
                      <div className="m-0 p-0">
                        <label className="form-control-sm text-center m-0 p-1">
                          участник №1:
                          <select
                            className="form-control form-control-sm text-center m-0 p-1"
                          >
                            <option value="">
                              {dataRationalDetail["author_1_foreign_key_field"]["last_name_char_field"]}{" "}
                              {dataRationalDetail["author_1_foreign_key_field"]["first_name_char_field"]}{" "}
                              {dataRationalDetail["author_1_foreign_key_field"]["patronymic_char_field"]}{" "}
                              {dataRationalDetail["author_1_foreign_key_field"]["position_char_field"]}
                            </option>
                          </select>
                        </label>
                        <label className="form-control-sm text-center m-0 p-1">
                          % Вклада 1 участника
                          <input
                            type="number"
                            className="form-control form-control-sm text-center m-0 p-1"
                            value={user1Perc}
                            onChange={(e) => user1PercSet(e.target.value)}
                            placeholder="пример: 70%"
                            min="1"
                            max="100"
                          />
                        </label>
                      </div>
                    )}
                    {dataRationalDetail["author_2_foreign_key_field"] && (
                      <div className="m-0 p-0">
                        <label className="form-control-sm text-center m-0 p-1">
                          участник №2:
                          <select
                            className="form-control form-control-sm text-center m-0 p-1"
                          >
                            <option value="">
                              {dataRationalDetail["author_2_foreign_key_field"]["last_name_char_field"]}{" "}
                              {dataRationalDetail["author_2_foreign_key_field"]["first_name_char_field"]}{" "}
                              {dataRationalDetail["author_2_foreign_key_field"]["patronymic_char_field"]}{" "}
                              {dataRationalDetail["author_2_foreign_key_field"]["position_char_field"]}
                            </option>
                          </select>
                        </label>
                        <label className="form-control-sm text-center m-0 p-1">
                          % Вклада 2 участника
                          <input
                            type="number"
                            className="form-control form-control-sm text-center m-0 p-1"
                            value={user2Perc}
                            onChange={(e) => user2PercSet(e.target.value)}
                            placeholder="пример: 70%"
                            min="1"
                            max="100"
                          />
                        </label>
                      </div>
                    )}
                    {dataRationalDetail["author_3_foreign_key_field"] && (
                      <div className="m-0 p-0">
                        <label className="form-control-sm text-center m-0 p-1">
                          участник №3:
                          <select
                            className="form-control form-control-sm text-center m-0 p-1"
                          >
                            <option value="">
                              {dataRationalDetail["author_3_foreign_key_field"]["last_name_char_field"]}{" "}
                              {dataRationalDetail["author_3_foreign_key_field"]["first_name_char_field"]}{" "}
                              {dataRationalDetail["author_3_foreign_key_field"]["patronymic_char_field"]}{" "}
                              {dataRationalDetail["author_3_foreign_key_field"]["position_char_field"]}
                            </option>
                          </select>
                        </label>
                        <label className="form-control-sm text-center m-0 p-1">
                          % Вклада 3 участника
                          <input
                            type="number"
                            className="form-control form-control-sm text-center m-0 p-1"
                            value={user3Perc}
                            onChange={(e) => user3PercSet(e.target.value)}
                            placeholder="пример: 70%"
                            min="1"
                            max="100"
                          />
                        </label>
                      </div>
                    )}
                    {dataRationalDetail["author_4_foreign_key_field"] && (
                      <div className="m-0 p-0">
                        <label className="form-control-sm text-center m-0 p-1">
                          участник №4:
                          <select
                            className="form-control form-control-sm text-center m-0 p-1"
                          >
                            <option value="">
                              {dataRationalDetail["author_4_foreign_key_field"]["last_name_char_field"]}{" "}
                              {dataRationalDetail["author_4_foreign_key_field"]["first_name_char_field"]}{" "}
                              {dataRationalDetail["author_4_foreign_key_field"]["patronymic_char_field"]}{" "}
                              {dataRationalDetail["author_4_foreign_key_field"]["position_char_field"]}
                            </option>
                          </select>
                        </label>
                        <label className="form-control-sm text-center m-0 p-1">
                          % Вклада 4 участника
                          <input
                            type="number"
                            className="form-control form-control-sm text-center m-0 p-1"
                            value={user4Perc}
                            onChange={(e) => user4PercSet(e.target.value)}
                            placeholder="пример: 70%"
                            min="1"
                            max="100"
                          />
                        </label>
                      </div>
                    )}
                    {dataRationalDetail["author_5_foreign_key_field"] && (
                      <div className="m-0 p-0">
                        <label className="form-control-sm text-center m-0 p-1">
                          участник №5:
                          <select
                            className="form-control form-control-sm text-center m-0 p-1"
                          >
                            <option value="">
                              {dataRationalDetail["author_5_foreign_key_field"]["last_name_char_field"]}{" "}
                              {dataRationalDetail["author_5_foreign_key_field"]["first_name_char_field"]}{" "}
                              {dataRationalDetail["author_5_foreign_key_field"]["patronymic_char_field"]}{" "}
                              {dataRationalDetail["author_5_foreign_key_field"]["position_char_field"]}
                            </option>
                          </select>
                        </label>
                        <label className="form-control-sm text-center m-0 p-1">
                          % Вклада 5 участника
                          <input
                            type="number"
                            className="form-control form-control-sm text-center m-0 p-1"
                            value={user5Perc}
                            onChange={(e) => user5PercSet(e.target.value)}
                            placeholder="пример: 70%"
                            min="1"
                            max="100"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="m-0 p-0">
                    <Link to={`#`} className="btn btn-sm btn-warning m-0 p-2">
                      Автор:{" "}
                      {dataRationalDetail["user_model"]["last_name_char_field"]}{" "}
                      {dataRationalDetail["user_model"]["first_name_char_field"]}{" "}
                      {dataRationalDetail["user_model"]["position_char_field"]}
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between m-0 p-1">
                    <label className="text-muted border p-1 m-0 p-1">
                      подано:{" "}
                      <p className="m-0 p-0">
                        {utils.GetCleanDateTime(
                          dataRationalDetail["created_datetime_field"],
                          true
                        )}
                      </p>
                    </label>
                    <label className="text-muted border p-1 m-0 p-1">
                      зарегистрировано:{" "}
                      <p className="m-0 p-0">
                        {utils.GetCleanDateTime(
                          dataRationalDetail["register_datetime_field"],
                          true
                        )}
                      </p>
                    </label>
                  </div>
                </div>
                <div className="card-footer m-0 p-0">
                  <ul className="btn-group row nav row-cols-auto row-cols-md-auto row-cols-lg-auto justify-content-center m-0 p-0">
                    <button
                      className="btn btn-sm btn-primary m-1 p-2"
                      type="submit"
                    >
                      <i className="fa-solid fa-circle-check m-0 p-1" />
                      заменить данные
                    </button>
                    <button
                      className="btn btn-sm btn-warning m-1 p-2"
                      type="reset"
                      onClick={(e) => handlerChangeReset(e)}
                    >
                      <i className="fa-solid fa-pen-nib m-0 p-1" />
                      сбросить данные
                    </button>
                  </ul>
                </div>
              </div>
              <div className="card-footer m-0 p-0">
                <div className="card m-0 p-2">
                  <div className="order-md-last m-0 p-0">
                    <components.StoreStatusComponent
                      storeStatus={rationalCommentDeleteStore}
                      keyStatus={"ideaCommentDeleteStore"}
                      consoleLog={constants.DEBUG_CONSTANT}
                      showLoad={true}
                      loadText={""}
                      showData={true}
                      dataText={""}
                      showError={true}
                      errorText={""}
                      showFail={true}
                      failText={""}
                    />
                    {!dataRationalDetail["comments"] ||
                    (dataRationalDetail["comments"]["comments"] &&
                      dataRationalDetail["comments"]["comments"].length) < 1 ? (
                      <div className="my-1">
                        <components.MessageComponent variant={"warning"}>
                          Комментарии не найдены!
                        </components.MessageComponent>
                      </div>
                    ) : (
                      <ul className="list-group m-0 p-0">
                        {dataRationalDetail["comments"]["comments"].map(
                          (object, index) => (
                            <li className="list-group-item m-0 p-1">
                              <div className="d-flex justify-content-between m-0 p-0">
                                <h6 className="btn btn-outline-warning m-0 p-2">
                                  {object["user_model"]["last_name_char_field"]}{" "}
                                  {
                                    object["user_model"][
                                      "first_name_char_field"
                                    ]
                                  }
                                </h6>
                                <span className="text-muted m-0 p-0">
                                  {utils.GetCleanDateTime(
                                    object["created_datetime_field"],
                                    true
                                  )}
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-danger m-1 p-1"
                                    onClick={(e) =>
                                      handlerCommentDelete({
                                        commentId: object["id"],
                                      })
                                    }
                                  >
                                    удалить комментарий
                                  </button>
                                </span>
                              </div>
                              <div className="d-flex justify-content-center m-0 p-1">
                                <small className="text-muted m-0 p-1">
                                  {object["comment_text_field"]}
                                </small>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </ul>
        )}
      </main>
      <components.FooterComponent />
    </div>
  );
};
