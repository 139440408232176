// TODO download modules ///////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
// TODO custom modules /////////////////////////////////////////////////////////////////////////////////////////////////
import * as components from "../../js/components";
// TODO default export const page //////////////////////////////////////////////////////////////////////////////////////
export const NewsPage = () => {
  // TODO return page //////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="m-0 p-0">
      <components.HeaderComponent />
      <main>
        <components.NewsComponent count={100} />
      </main>
      <components.FooterComponent />
    </div>
  );
};
