// TODO download modules ///////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// TODO custom modules /////////////////////////////////////////////////////////////////////////////////////////////////
import * as utils from "../../js/utils";
import * as actions from "../../js/actions";
// TODO default export const page //////////////////////////////////////////////////////////////////////////////////////
export const LogoutPage = () => {
  // TODO react hooks variables ////////////////////////////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // TODO useEffect hooks //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(actions.userLogoutAction());
    navigate("/login");
  }, [dispatch]);
  // TODO return page //////////////////////////////////////////////////////////////////////////////////////////////////
  return <div className="m-0 p-0">.</div>;
};
